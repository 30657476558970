<template>
  <div>

    <Spinner v-if="loading === true"/>

    <div
        v-if="loading === false"
        class="row"
    >
      <div class="col-md-6"/>
      <div class="col-md-6 create-button">
        <!--        <button-->
        <!--          id="deleteMultiple"-->
        <!--          type="button"-->
        <!--          class="btn btn-outline-primary mb-2"-->
        <!--          @click="confirmDeleteText(deletedArry)"-->
        <!--        >-->
        <!--          Delete Selected Elements-->
        <!--        </button>-->
        <button
            type="button"
            class="btn btn-primary mb-2 ml-1"
            data-toggle="modal"
            data-target="#createModal"
            data-backdrop="static"
            data-keyboard="false"
        >
          Create
        </button>
        <create
            v-if="$router.currentRoute.name === 'user-rooms'"
            @refresh="renderTable()"
        />
      </div>

    </div>
    <basic-table
        v-if="loading === false"
        ref="table"
        :columns="columns"
        :values="rows"
        :actions-obj="actionsArray"
        :editable-page="false"
        :deletable-page="false"
        @dynamicAction="handleActions($event)"
        @choosenObject="getEditedObject($event)"
        @deleteItems="removeItems($event)"
        @deletedMultipleIds="catchIds($event)"
        @refresh="renderTable()"
    />
    <edit
        v-if="$router.currentRoute.name === 'user-rooms'"
        :item="editedItem"
        @refresh="renderTable()"
    />
  </div>
</template>
<script>
import {BRow, BCol} from 'bootstrap-vue'
import Spinner from '@/shared/loader/Spinner'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './rooms/create.vue'
import Edit from './rooms/edit.vue'
import invoiceService from '../../../services/invoiceService'
import Roles from './roles.vue'
import ResetPassword from './resetPassword.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    ResetPassword,
    Roles,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      userId: '',
      actionsArray: [],
      editedItem: '',
      roleId: '',
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'devicesCount',
          field: 'devicesCount',
        },
        {
          label: 'Room',
          field: 'room.title',
        },
        {
          label: 'Default',
          field: 'room.is_default',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getInvoices()
    this.actionsArray = [
      {title: 'Edit', icon: 'EditIcon', conditionalColumn: 'canEdit'},
      {title: 'Delete', icon: 'TrashIcon', conditionalColumn: 'canDelete'},
    ]
  },
  methods: {
    getInvoices() {
      this.loading = true
      invoiceService.showUserRooms(this.$route.params.id).then(response => {
        this.rows = response.data.data
        this.loading = false
        console.log('vvv', this.rows)
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Edit': {
          this.editedItem = itemObj.row
          $('#editModal').modal('show')
        }
          break
        case 'Delete': {
          var ids = []
          ids.push(itemObj.row.id)
          this.removeItems(ids)
        }
          break
      }
    },
    getEditedObject(obj) {
      this.editedItem = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.loading = false
            invoiceService.deleteUserRoom(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              this.loading = false
              return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getInvoices()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
