<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
      id="createModal"
      class="modal"

  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              :title="`Create Room`"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="title">Title</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules=""
                      >
                        <b-form-input
                            id="title"
                            v-model="createdRoom.title"
                            name="title"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="room">Room</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Room"
                          rules="required"
                      >
                        <v-select
                            name="roles"
                            v-model="createdRoom.room_id"
                            class="parent-service"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="rooms"
                            :reduce="title => title.id"
                            label="title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--                  <b-col md="12">-->
                  <!--                    <b-form-group>-->
                  <!--                      <label for="user">User</label>-->
                  <!--                      <validation-provider-->
                  <!--                          #default="{ errors }"-->
                  <!--                          name="User"-->
                  <!--                          rules="required"-->
                  <!--                      >-->
                  <!--                        <v-select-->
                  <!--                            name="roles"-->
                  <!--                            v-model="createdRoom.user"-->
                  <!--                            class="parent-service"-->
                  <!--                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                  <!--                            :options="users"-->
                  <!--                            :reduce="full_name => full_name.id"-->
                  <!--                            label="full_name"-->
                  <!--                        />-->
                  <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                  <!--                      </validation-provider>-->
                  <!--                    </b-form-group>-->
                  <!--                  </b-col>-->
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from '../code'
import FormTextAreaDefault from '../../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import adminService from '../../../../services/adminService'
import roomsService from '../../../../services/roomsService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      users: [],
      rooms: [],
      userId: '',
      createdRoom: {
        title: '',
        user_id: '',
        room_id: '',
      },
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
    }
  },
  mounted() {

    this.getRooms()
    this.getClients()
    this.$watch(() => this.$route.params.id, user => {
      this.userId = user

    })

  },
  methods: {
    getClients() {
      this.loading = true
      adminService.users('Client')
          .then(response => {
            this.users = response.data.data
          })
    },
    getRooms() {
      this.loading = true
      roomsService.availableRooms(this.$route.params.id)
          .then(response => {
            this.rooms = response.data.data
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.createdRoom.user_id =  this.$route.params.id
              roomsService.assignRoomToUser(this.createdRoom)
                  .then(response => {
                    if (response && response.data.code === 200) {
                      $('#createModal').modal('hide')
                      this.$emit('refresh', 'true')
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Room is created successfully !',
                        },
                      })
                    } else{
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Error',
                          icon: 'CheckIcon',
                          variant: 'danger',
                          text: response.data.message,
                        },
                      })
                      $('#createModal').modal('hide')

                    }
                    this.loading = false
                  })
            }
          })
          .catch(function (error) {
            $('#createModal')
                .modal('hide')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'Error',
                text: 'Error !',
              },
            })
            return Promise.reject(error)
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
